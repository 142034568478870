// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-container {
  margin: 0 auto;
  max-width: 700px;
  padding: 0 1rem;
  text-align: center; }
  .menu-container > img {
    margin-bottom: 2.5rem;
    margin-top: 4.5rem; }
  .menu-container button {
    background: #4898D3;
    border: none;
    border-radius: 8px;
    color: white;
    display: block;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    margin: 1rem auto 0;
    padding: 0;
    width: 312px; }
  .menu-container p {
    margin-bottom: 5rem; }

@media screen and (orientation: portrait) {
  .menu-container {
    max-width: 500px; } }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/Menu.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,kBAAkB,EAAA;EAJpB;IAOI,qBAAqB;IACrB,kBAAkB,EAAA;EARtB;IAYI,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;IACnB,UAAU;IACV,YAAY,EAAA;EAtBhB;IA0BI,mBAAmB,EAAA;;AAIvB;EACE;IACE,gBAAgB,EAAA,EACjB","sourcesContent":[".menu-container {\n  margin: 0 auto;\n  max-width: 700px;\n  padding: 0 1rem;\n  text-align: center;\n\n  > img {\n    margin-bottom: 2.5rem;\n    margin-top: 4.5rem;\n  }\n\n  button {\n    background: #4898D3;\n    border: none;\n    border-radius: 8px;\n    color: white;\n    display: block;\n    font-size: 14px;\n    font-weight: 500;\n    height: 40px;\n    margin: 1rem auto 0;\n    padding: 0;\n    width: 312px;\n  }\n\n  p {\n    margin-bottom: 5rem;\n  }\n}\n\n@media screen and (orientation: portrait) {\n  .menu-container {\n    max-width: 500px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
