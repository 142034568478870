import { isExpired } from "react-jwt";
import {getLocal} from "./dataFetching";

export const getToken = () => getLocal('auth_token');

export const isTokenExpired = (token) => {
  const decodedToken = isExpired(token);
  if (!decodedToken) return true;

  return false;
};