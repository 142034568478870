import React, {useState} from 'react';
import '../stylesheets/Header.scss';

function Header ({ avatarName, avatarSrc }) {
  const [isEnding, setIsEnding] = useState(false);

  return (
    <>
      <div className="flex-grow-1 header-container my-2 py-4 d-flex justify-content-center align-items-center">
        <img src={'../../assets/default/images/header-' + avatarSrc + '.png'} alt={avatarName + " - Video Call"} />
        <button className="not-mobile" onClick={() => setIsEnding(true)}>&lt; menu</button>
        <button className="mobile" onClick={() => setIsEnding(true)}>&lt; menu</button>
      </div>
      <div className={'end-modal-overlay' + (isEnding ? ' visible' : '')}>
        <div className="end-modal d-flex flex-wrap">
          <span>Do you want to exit this session?</span>
          <div className="d-flex">
            <button onClick={() => window.location = '/menu'}>Exit</button>
            <button onClick={() => setIsEnding(false)}>Continue</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
