import React, { useRef } from 'react';
import '../stylesheets/Overlay.scss';
import closeSVG from '../stylesheets/svg/Close.svg';

const Overlay = ({ isOpen, onClose, headerTitle, children }) => {
  const overlayRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (overlayRef.current && !overlayRef.current.contains(e.target)) {
      onClose(e);
    }
    e.stopPropagation();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="overlay" onClick={handleOutsideClick}>
        <div className='overlay-box-container'>
          <div className="overlay-header">
            <div className="spacing-div"></div>
            <h4>{headerTitle ? headerTitle : ''}</h4>
            <button className="close-btn" onClick={onClose}>
                <span className="close-icon">close <img src={closeSVG} alt="" /></span>
            </button>
          </div>
          <div className="overlay-content" ref={overlayRef}>
            {children}
          </div>
        </div>
    </div>
  );
};

export default Overlay;