import React, {useCallback, useEffect, useRef, useState} from 'react';
import AudioControl from './AudioControl';

import '../stylesheets/AvatarVideo.scss';
import {useAddHistory} from "../../utils/hooks/historyHooks";
import {getLLMResponse} from "../../utils/fetch/llmFetching";
import streamingClient from "../../utils/streaming-client-api";
import {emptyTranscribeResponse} from "../../utils/constants";

const AvatarVideo = ({sessionId, modId, step, avatarIdle}) => {
  const [audioState, setAudioState] = useState('ready');
  const [streamState, setStreamState] = useState('connecting');
  const [streamInfo, setStreamInfo] = useState('Connecting to avatar... Please wait');
  const [isTalking, setIsTalking] = useState(false);
  const idleRef = useRef(null);
  const talkRef = useRef(null);
  const {mutateAsync:newHistoryObj} = useAddHistory();

  const handlePlayVideo = useCallback(async (text) => {
    if (talkRef.current) {
      // talkRef.current.src = '/talk.mp4';
      // talkRef.current.play();
      await streamingClient.playStreamText(text);
      await newHistoryObj({sender: 'AI', text: text});
      console.log('played talkRef');
    }
  }, [talkRef, newHistoryObj]);

  const handleAudioStateChange = useCallback(async (state, transRes) => {

    if (state === 'ready') {
      if (transRes && transRes.transcription) {
        await newHistoryObj({sender: 'Human', text: transRes.transcription});
        
        const llmRes = await getLLMResponse(sessionId, modId, transRes.transcription, "", "", "", "", "", true, {type:"count",value:50});

        if (llmRes.data?.content) {
          let c = llmRes.data.content;

          await handlePlayVideo(c);
        } 
      } else {
        await handlePlayVideo(emptyTranscribeResponse);
      }
    } else {
      console.log(state);
      setIsTalking(false);
      talkRef.current?.pause();
      setAudioState(state);
    }

  }, [newHistoryObj, handlePlayVideo, sessionId, modId]);

  const handleVideoEnded = useCallback (() => {
    idleRef.current.play();
    setIsTalking(false);
    setAudioState('listening');
  },[idleRef]);

  useEffect(() => {
    // Define the function that will be called when the event is triggered
    const handleStreamingClientAPI = (evt) => {
      console.log('handleStreamingClientAPI: ',evt);
      switch (evt.detail?.message) {
        case 'idle':
          return handleVideoEnded();
        case 'playing':
          setAudioState('ready');
          idleRef.current.pause();
          return setIsTalking(true);
        default:
          setStreamState(evt.detail.message);
          setStreamInfo(evt.detail.info || '');
          console.log(evt.detail.message);
      }
    };

    // Add event listener when component mounts
    window.addEventListener('streaming-client-api', handleStreamingClientAPI);
    window.dispatchEvent(new CustomEvent('streaming-client-avatar',{detail:{message:'onload'}}));

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener('streaming-client-api', handleStreamingClientAPI);
    };
  }, [handleVideoEnded, idleRef]);

  return (
    <div className={audioState + " avatar-container flex-grow-1 " + (isTalking ? "talking " : "") + streamState}>
      <video id="idle-video" className="idle-video" ref={idleRef} autoPlay loop muted playsInline>
        <source src={avatarIdle} type="video/mp4"/>
        Your browser does not support the video tag.
      </video>
      <video id="talk-video" className="talk-video" ref={talkRef} autoPlay playsInline onEnded={handleVideoEnded}>
        <source src={avatarIdle} type="video/mp4"/>
        Your browser does not support the video tag.
      </video>

      <AudioControl isTalking={isTalking} audioState={streamState === 'connecting' ? 'processing' : audioState} onStateChange={handleAudioStateChange} step={step} />
      {streamState !== 'connected' && streamInfo && <div className="stream-info p-2">{streamInfo}</div>}
    </div>
  );
};

export default AvatarVideo;

