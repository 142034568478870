import { ErrorBoundary } from "react-error-boundary";
import "../stylesheets/ErrorBoundary.scss";


const ErrorPage = (props) => {
    return (
        <div className="ErrorBoundary">
            <div className={"oops"}>Oops!</div>
            <div className={"message"}>Something went wrong... please see your trainer for assistance</div>
            {props.resetErrorBoundary && (
                <div>
                    <button className={"retry-button"} onClick={props.resetErrorBoundary}>
                        Main Menu
                    </button>
                </div>
            )}
        </div>
    );
}

const ReactErrorBoundary = ({ children }) => {
    return (
        <ErrorBoundary
            FallbackComponent={ErrorPage}
            onError={(error, errorInfo) => {
                // log the error
                console.log("Error caught!");  
                console.error(error);  
                console.error(errorInfo);
                
                // record the error in an APM tool...
            }}
            onReset={() => {
                // reloading the page to restore the initial state
                // of the current page
                // console.log("reloading the page...");
                // window.location.reload();
                window.location.replace("/menu");

                // other reset logic...
            }}
        >
            {children}
        </ErrorBoundary>
    );
}

export default ReactErrorBoundary;